import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DocumentTemplate } from '@ee/common/models';
import {FormService} from '@ee/common/services';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'ee-document-selector',
  template: `
    <div class="flex justify-start items-stretch" [ngClass]="{'flex-col': vertical, 'flex-row': !vertical}" *ngIf="templates; else noTemplates">
      <div class="flex-1 flex flex-col justify-stretch items-stretch" [ngClass]="{'mr-4': !vertical}">
        <div class="subtitle font-medium">
          <i class="fa fa-eye"></i> Document Templates Visible to Clients and Your Firm
        </div>
        <ng-select class="flex-1 round"
                   [items]="templates"
                   [multiple]="true"
                   [selectableGroup]="false"
                   [closeOnSelect]="false"
                   [maxSelectedItems]="maxVisible"
                   [searchable]="true"
                   bindLabel="name"
                   groupBy="type"
                   bindValue="id"
                   appendTo="body"
                   [formControl]="$any(visibleDocumentTemplates)">
          <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
            {{item.type | uppercase}}
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                   [ngModelOptions]="{ standalone : true }"/> {{item.name}}
          </ng-template>
        </ng-select>
        <div class="max-reached" *ngIf="maxVisible > 0 && visibleDocumentTemplates.getRawValue()?.length === maxVisible">
            No more than {{maxVisible}} documents can be selected.
        </div>
      </div>
      <div class="flex-1 flex flex-col justify-stretch items-stretch">
        <div class="subtitle font-medium">
          <i class="fa fa-eye-slash"></i> Document Templates Only Visible to Firm
        </div>
        <ng-select class="flex-1 round"
                   [items]="templates"
                   [multiple]="true"
                   [selectableGroup]="false"
                   [closeOnSelect]="false"
                   [maxSelectedItems]="maxHidden"
                   [searchable]="true"
                   bindLabel="name"
                   groupBy="type"
                   bindValue="id"
                   appendTo="body"
                   [formControl]="$any(hiddenDocumentTemplates)">
          <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
            {{item.type | uppercase}}
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [(ngModel)]="item$.selected"
                   [ngModelOptions]="{ standalone : true }"/> {{item.name}}
          </ng-template>
        </ng-select>
        <div class="max-reached" *ngIf="maxHidden > 0 && hiddenDocumentTemplates.getRawValue()?.length === maxHidden">
            No more than {{maxHidden}} documents can be selected.
        </div>
      </div>
    </div>
    <ng-template #noTemplates>
      <div class="flex justify-center items-center">
        No document templates available.
      </div>
    </ng-template>
  `,
  styles: [
    `
      .max-reached {
        font-size: 0.8rem;
        color: #ff0000;
      }
    `
  ]
})
export class DocumentSelectorComponent implements OnInit, OnDestroy {
  @Input() vertical = false;
  @Input({ required: true })
    visibleDocumentTemplates: UntypedFormControl;
  @Input({ required: true })
    hiddenDocumentTemplates: UntypedFormControl;
  @Input() maxVisible = 20;
  @Input() maxHidden = 20;
  public templates: DocumentTemplate[] = [];
  private subscriptions: Subscription[] = [];

  public templates$: Observable<DocumentTemplate[]>;

  constructor(private formService: FormService) {}

  ngOnInit() {
    // remove any pre-filled form templates that are not in the list of available templates
    const visibleDocumentTemplates = this.visibleDocumentTemplates.getRawValue();
    const hiddenDocumentTemplates = this.hiddenDocumentTemplates.getRawValue();

    this.subscriptions.push(this.formService.getAllForms().subscribe(templates => {
      this.templates = templates;
      this.visibleDocumentTemplates.setValue(visibleDocumentTemplates.filter((t: string) => this.templates.find(x => x.id === t)));
      this.hiddenDocumentTemplates.setValue(hiddenDocumentTemplates.filter((t: string) => this.templates.find(x => x.id === t)));
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
