import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormControl, Validators} from '@angular/forms';
import {UploadSelectorComponent} from './upload-selector.component';

@Component({
  selector: 'ee-uploader',
  template: `
    <div [ngClass]="additionalClasses">
      <div class="mb-2">
        <div *ngIf="!!label" class="flex flex-row justify-between items-center">
          <h3 class="no-wrap">{{ label }}</h3>
        </div>
        <div class="flex flex-row justify-between items-start">
          <div *ngIf="!form?.untouched && form?.invalid; else valid" class="errors">
            <b
              *ngIf="form?.hasError('required'); else sizeLabel">
              Document must be selected to continue.
            </b>
            <ng-template #sizeLabel>
              <b *ngIf="form?.hasError('maxContentSize')">
                The total size must not
                exceed {{ form?.getError('maxContentSize').maxSize | bytes : 0 : 'B' : 'MB' }}).</b>
            </ng-template>
          </div>
          <ng-template #valid>
            <div>
              <b *ngIf="form.hasValidator(Validators.required)">Required. </b><span *ngIf="!!hint">{{ hint }}.</span>
            </div>
          </ng-template>

          <div class="no-wrap cursor-pointer" (click)="clearSelectedFile(form)">
            <u>Clear Selection</u>
          </div>
        </div>
      </div>
      <div class="card-content flex flex-row justify-center items-center">
        <div *ngIf="downloadFileLink" class="flex flex-col flex-1 h-full w-full justify-start items-stretch mr-2">
          <div>1. Click here to download the form:</div>
          <button type="button" mat-flat-button color="primary" (click)="downloadForm(downloadFileLink)">
            Download Form
          </button>
          <div>2. Print and fill out the form</div>
          <div>3. Upload form here</div>
        </div>
        <ee-upload-selector #uploadSelected class="flex-1"
                            [formControl]="$any(form)" [required]="form.hasValidator(Validators.required)"
                            [hasError]="$any(form).touched && form?.invalid" [accept]="accept">
        </ee-upload-selector>
      </div>
    </div>
  `,
  styles: [
    `
      @import 'components/color-palette';
      @import 'components/utils';

      h3 {
        font-size: 1rem;
      }

      .mat-mdc-form-field {
        margin-bottom: 1rem;
      }

      .no-wrap {
        white-space: nowrap;
      }

      .errors {
        color: $primary-red;
        text-align: right;
      }

      .upload-wrapper {
        margin-bottom: 1rem;

        .label {
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 1.5rem;
          padding-bottom: 0.5rem;
          text-align: center;
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploaderComponent {
  @Input({required: false}) label: string;
  @Input({required: false}) hint: string;
  @Input({required: false}) downloadFileLink: string;
  @Input({required: false}) accept: string = '*';
  @Input({required: false}) additionalClasses: string = '';
  @Input() form: UntypedFormControl;
  @Output() downloadFormEvent = new EventEmitter<string>();

  @ViewChild('uploadSelected') uploadSelected: UploadSelectorComponent;

  public visibility: boolean[] = [];
  public required: boolean[] = [];

  constructor(private cdr: ChangeDetectorRef) {
  }

  public downloadForm(formLink: string): void {
    this.downloadFormEvent.emit(formLink);
  }

  public clearSelectedFile(control: AbstractControl) {
    control.setValue(undefined);
    this.uploadSelected?.clear();
    this.cdr.detectChanges();
  }

  protected readonly Validators = Validators;
}
