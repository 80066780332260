import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CustomFieldType, DocumentType} from '@ee/common/enums';
import {FormControl} from '@angular/forms';
import {CustomField} from '@ee/common/models';

@Component({
  selector: 'ee-field',
  template: `
    <div [ngSwitch]="field.type">
      <mat-form-field class="w-full" *ngSwitchCase="fieldTypes.DATE" [class]="formClass">
        <mat-label>{{field.label}}</mat-label>
        <input matInput [formControl]="$any(control)" [matDatepicker]="picker"
               [required]="field.required">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-hint *ngIf="!hideHint">
          <ng-container [ngTemplateOutlet]="hint"></ng-container>
        </mat-hint>
      </mat-form-field>
      <mat-form-field class="w-full" *ngSwitchCase="fieldTypes.DECIMAL" [class]="formClass">
        <mat-label>{{field.label}}</mat-label>
        <span matPrefix>$ &nbsp;</span>
        <input matInput type="number" [formControl]="$any(control)" [required]="field.required">
        <mat-hint *ngIf="!hideHint">
          <ng-container [ngTemplateOutlet]="hint"></ng-container>
        </mat-hint>
      </mat-form-field>
      <mat-form-field class="w-full" *ngSwitchCase="fieldTypes.SELECT" [class]="formClass">
        <mat-label>{{field.label}}</mat-label>
        <mat-select [formControl]="$any(control)" [required]="field.required">
          <mat-option *ngFor="let option of field.options" [value]="option">
            {{option}}
          </mat-option>
        </mat-select>
        <mat-hint *ngIf="!hideHint">
          <ng-container [ngTemplateOutlet]="hint"></ng-container>
        </mat-hint>
      </mat-form-field>
      <div class="w-full" *ngSwitchCase="fieldTypes.BOOLEAN">
        <div *ngIf="field.true_label && field.false_label; else defaultBoolean">
          <mat-radio-group class="ee" [formControl]="$any(control)">
            <mat-radio-button class="boolean-selection mr-4" value="true">
              <b>{{field.true_label}}</b>
            </mat-radio-button>
            <mat-radio-button class="boolean-selection" value="false">
              <b>{{field.false_label}}</b>
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <ng-template #defaultBoolean>
          <mat-checkbox class="w-full mb-4" [formControl]="$any(control)" [class]="formClass">
            {{field.label}}
          </mat-checkbox>
        </ng-template>
      </div>
      <mat-form-field class="w-full" *ngSwitchCase="fieldTypes.TIME" [class]="formClass">
        <mat-label>{{field.label}}</mat-label>
        <input matInput [formControl]="$any(control)"
               [required]="field.required" [ngxMatTimepicker]="matTimepicker">
        <mat-hint *ngIf="!hideHint">
          <ng-container [ngTemplateOutlet]="hint"></ng-container>
        </mat-hint>
        <ngx-mat-timepicker #matTimepicker [defaultTime]="'3:00 pm'"></ngx-mat-timepicker>
      </mat-form-field>
      <ee-uploader *ngSwitchCase="fieldTypes.FILE_UPLOAD" class="w-full" [form]="$any(control)" [class]="formClass"></ee-uploader>
      <mat-form-field class="w-full" *ngSwitchDefault [class]="formClass">
        <mat-label>{{field.label}}</mat-label>
        <input matInput [formControl]="$any(control)" [required]="field.required">
        <mat-hint *ngIf="!hideHint">
          <ng-container [ngTemplateOutlet]="hint"></ng-container>
        </mat-hint>
      </mat-form-field>
      <ng-template #hint>
        <span>{{ field.required ? 'Required' : 'Optional' }}. </span>
        <span *ngIf="field.hint">{{field.hint}}. </span>
        <span class="field-instructions-wrapper" *ngIf="field.hint_more_info">
                Hover for <span class="field-instructions" [tooltip]="field.hint_more_info" placement="bottom">
                more info</span>.
        </span>
      </ng-template>
    </div>
  `,
  styles: [`
    @import 'components/color-palette';

    .w-full {
      width: 100% !important;
    }

    .boolean-selection {
      min-width: 100px;
    }

    .field-instructions {
      color: $primary-red;
      text-decoration: underline;
      text-decoration-style: dashed;
      cursor: pointer;
      font-weight: 600;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldComponent {
  fieldTypes = CustomFieldType;
  @Input() control: FormControl;
  @Input() field: CustomField;
  @Input() compact = false;
  @Input() hideFromIntake = false;
  @Input() hideHint = false;

  public get formClass(): string {
    let classes = '';
    if (this.compact) {
      classes = 'compact';
    }
    if (this.hideFromIntake) {
      classes += ' hidden-from-intake';
    }
    return classes;
  }

  protected readonly formType = DocumentType;
}
