import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CustomFieldType} from '@ee/common/enums';
import {UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'ee-custom-field',
  template: `
    <div class="w-full flex flex-row justify-center items-center" [ngSwitch]="form.get('type').value" [formGroup]="form">
      <mat-form-field *ngSwitchCase="fieldTypes.DATE" class="compact flex-1"
                      [ngClass]="{'hidden-from-intake': hideFromIntake}">
        <mat-label>{{form.get('label').value}}</mat-label>
        <input matInput formControlName="value" [matDatepicker]="picker"
               [required]="!builder && form.get('required').value">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-hint>
          <ng-container  [ngTemplateOutlet]="hint"></ng-container>
        </mat-hint>
      </mat-form-field>
      <mat-form-field *ngSwitchCase="fieldTypes.DECIMAL" class="compact flex-1"
                      [ngClass]="{'hidden-from-intake': hideFromIntake}">
        <mat-label>{{form.get('label').value}}</mat-label>
        <span matPrefix>$ &nbsp;</span>
        <input matInput type="number" formControlName="value"
               [required]="!builder && form.get('required').value">
        <mat-hint>
          <ng-container  [ngTemplateOutlet]="hint"></ng-container>
        </mat-hint>
      </mat-form-field>
      <mat-form-field *ngSwitchCase="fieldTypes.SELECT" class="compact flex-1"
                      [ngClass]="{'hidden-from-intake': hideFromIntake}">
        <mat-label>{{form.get('label').value}}</mat-label>
        <mat-select formControlName="value" [required]="!builder && form.get('required').value">
          <mat-option *ngFor="let option of form.get('options').value" [value]="option">
            {{option}}
          </mat-option>
        </mat-select>
        <mat-hint>
          <ng-container  [ngTemplateOutlet]="hint"></ng-container>
        </mat-hint>
      </mat-form-field>
      <div *ngSwitchCase="fieldTypes.BOOLEAN" class="flex-1">
        <div *ngIf="form.get('true_label').value && form.get('false_label').value; else defaultBoolean">
          <mat-radio-group class="ee" formControlName="value">
            <mat-radio-button class="boolean-selection mr-4" [value]="form.get('true_label').value">
              <b>{{form.get('true_label').value}}</b>
            </mat-radio-button>
            <mat-radio-button class="boolean-selection" [value]="form.get('false_label').value">
              <b>{{form.get('false_label').value}}</b>
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <ng-template #defaultBoolean>
          <mat-checkbox formControlName="value" class="compact mb-4">
            {{form.get('label').value}}
          </mat-checkbox>
        </ng-template>
      </div>
      <mat-form-field *ngSwitchCase="fieldTypes.TIME" class="compact flex-1"
                      [ngClass]="{'hidden-from-intake': hideFromIntake}">
        <mat-label>{{form.get('label').value}}</mat-label>
        <input matInput formControlName="value"
               [required]="!builder && form.get('required').value" [ngxMatTimepicker]="matTimepicker">
        <mat-hint>
          <ng-container  [ngTemplateOutlet]="hint"></ng-container>
        </mat-hint>
        <ngx-mat-timepicker #matTimepicker [defaultTime]="'3:00 pm'"></ngx-mat-timepicker>
      </mat-form-field>

      <ee-uploader *ngSwitchCase="fieldTypes.FILE_UPLOAD" class="w-full" [form]="$any(form.get('value'))"
                   [ngClass]="{'hidden-from-intake': hideFromIntake}"></ee-uploader>

      <mat-form-field *ngSwitchDefault class="compact flex-1"
                      [ngClass]="{'hidden-from-intake': hideFromIntake}">
        <mat-label>{{form.get('label').value}}</mat-label>
        <input matInput formControlName="value" [required]="!builder && form.get('required').value">
        <mat-hint>
            <ng-container  [ngTemplateOutlet]="hint"></ng-container>
        </mat-hint>
      </mat-form-field>
      <ng-template #hint>
        <span>{{ form.get('required').value ? 'Required' : 'Optional' }}. </span>
        <span *ngIf="form.get('hint')?.value">{{form.get('hint')?.value}}. </span>
        <span class="field-instructions-wrapper" *ngIf="form.get('hint_more_info')?.value">
                Hover for <span class="field-instructions" [tooltip]="form.get('hint_more_info').value" placement="bottom">
                more info</span>.
        </span>
      </ng-template>
    </div>
  `,
  styles: [`
    @import 'components/color-palette';
    .boolean-selection {
      min-width: 100px;
    }
    .field-instructions {
      color: $primary-red;
      text-decoration: underline;
      text-decoration-style: dashed;
      cursor: pointer;
      font-weight: 600;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomFieldComponent implements OnInit, OnDestroy {
  fieldTypes = CustomFieldType;
  @Input() form: UntypedFormGroup;
  @Input() builder = false;
  @Input() hideFromIntake = false;

  private subs: Subscription[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.subs.push(this.form.valueChanges.subscribe( () => {
      this.cdr.detectChanges();
    }));
    this.subs.push(this.form.statusChanges.subscribe( () => {
      this.cdr.detectChanges();
    }));
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
