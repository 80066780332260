import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {BaseTask, GenerateBaseTaskForm, User} from '@ee/common/models';
import {AttorneyUserRoles, CaseTaskOperation, GeneralUserRoles, TaskType} from '@ee/common/enums';
import {Subscription} from 'rxjs';
import {CaseTaskEditorType} from '@ee/common/enums';

@Component({
  selector: 'ee-case-task-editor-dialog',
  template: `
    <h2 mat-dialog-title>{{ title }}</h2>
    <form mat-dialog-content class="flex flex-col justify-stretch" [formGroup]="form" (ngSubmit)="save()">

      <ee-task-form [taskForm]="form" [dynamicDescriptionHeight]="true"
                    [hideTaskScheduler]="data.editorType === CaseTaskEditorType.WorkflowEdit"></ee-task-form>

      @if (data.editorType === CaseTaskEditorType.WorkflowEdit) {
        <div class="mb-4 flex flex-row justify-start items-center">
          <tag-input class="flex-1 mr-2 dark-border" formControlName="roles" placeholder="+ another role" secondaryPlaceholder="Select a role"
                     [removable]="true" [allowDupes]="false" [clearOnBlur]="true" [modelAsStrings]="true" [draggable]="false"
                     [editable]="false" [dragZone]="'taskZone'">
            <tag-input-dropdown [showDropdownIfEmpty]="true"
                                [dynamicUpdate]="false"
                                [focusFirstElement]="true"
                                [displayBy]="'value'"
                                [identifyBy]="'value'"
                                [autocompleteItems]="roleTypes | keyvalue">
              <ng-template let-item="item" let-index="index">
                {{ item.value }}
              </ng-template>
            </tag-input-dropdown>
          </tag-input>
          <mat-icon class="cursor-pointer" fontSet="fa" fontIcon="fa-info-circle"
                    matTooltip="This task will be automatically assigned to users in your company matching the selected roles."></mat-icon>
        </div>
      }

      @if (data.type === TaskType.CASE_STEP_TASK) {
        <div class="mb-4 flex flex-row justify-start items-center">
          <mat-checkbox formControlName="required">Required</mat-checkbox>
          <mat-icon class="ml-1 mr-4 cursor-pointer" fontSet="fa" fontIcon="fa-info-circle"
                    matTooltip="The completion of the workflow step is blocked until this task has been completed."></mat-icon>
          <mat-checkbox formControlName="remove_on_step_completion">Remove on step completion</mat-checkbox>
          <mat-icon class="ml-1 cursor-pointer" fontSet="fa" fontIcon="fa-info-circle"
                    matTooltip="When enabled, this task will be removed if it is unfinished when the workflow step is processed."></mat-icon>
        </div>
      }

      @if (data.taskDetails?.update_history?.length) {
        <div class="update-history-wrapper">
          <div class="update-history-header">
            <div class="update-history-title">Update History</div>
            <button class="ee__button ee__chevron-button" mat-icon-button type="button" (click)="showUpdateHistory = !showUpdateHistory">
              <mat-icon fontSet="fa" [fontIcon]="showUpdateHistory ? 'fa-chevron-down' : 'fa-chevron-up'"></mat-icon>
            </button>
          </div>
          <div *ngIf="showUpdateHistory" class="update-history">
            @for (history of data.taskDetails.update_history; track history) {
              @if(history.field === 'marked_completed_date') {
                <div class="update-history-item">
                  Marked {{ history.current }} by {{ history.created_by }} on {{ history.created_date | dateTimeFromIso | dateTimeToFormat:'MMM d, yyyy h:mm a' }}
                </div>
              }
            }
          </div>
        </div>
      }
    </form>
    <div mat-dialog-actions class="ee-modal-actions-split">
      <div class="ee-modal-actions-split-left">
        <button mat-button (click)="delete()" color="primary"
                *ngIf="data.editorType !== CaseTaskEditorType.WorkflowEdit">
          DELETE
        </button>
      </div>
      <div class="ee-modal-actions">
        <button mat-button [mat-dialog-close]="null">CANCEL</button>
        <button mat-button (click)="save()" cdkFocusInitial color="accent" [disabled]="form.invalid">
          {{ data.editorType === CaseTaskEditorType.WorkflowEdit ? 'APPLY' : 'SAVE' }}
        </button>
      </div>
    </div>
  `,
  styles: [`
    .update-history-wrapper {
      border-top: 1px solid #D7D7D7;
      padding-top: 0.5rem;

      .update-history-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .update-history-title {
          font-weight: bold;
          margin-bottom: 0.5rem;
          text-align: center;
        }
      }

      .update-history-item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
      }
    }
  `]
})
export class CaseTaskEditorDialogComponent implements OnDestroy {
  form: UntypedFormGroup;
  title: string = 'Edit Task';
  roleTypes = Object.assign({}, AttorneyUserRoles, GeneralUserRoles);
  subs: Subscription[] = [];
  CaseTaskEditorType = CaseTaskEditorType;
  showUpdateHistory = false;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CaseTaskEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { taskDetails: BaseTask | null, type: TaskType, editorType: CaseTaskEditorType, users: User[] }
  ) {
    this.form = GenerateBaseTaskForm(this.fb, this.data.type, this.data.taskDetails);

    this.subs.push(
      this.form.get('required').valueChanges.subscribe(v => {
        if (v) {
          this.form.get('remove_on_step_completion').setValue(false);
          this.form.get('remove_on_step_completion').disable();
        } else {
          this.form.get('remove_on_step_completion').enable();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  save() {
    this.dialogRef.close({operation: CaseTaskOperation.Edit, form: this.form});
  }

  delete() {
    this.dialogRef.close({operation: CaseTaskOperation.Delete});
  }

  protected readonly TaskType = TaskType;
}
