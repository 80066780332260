import {AfterViewChecked, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BaseTask, User} from '@ee/common/models';
import {DateTime} from 'luxon';
import {MatCalendar} from '@angular/material/datepicker';
import {TaskType} from '@ee/common/enums';

@Component({
  selector: 'ee-workflow-task-info',
  template: `
    <div #trigger="cdkOverlayOrigin" cdkOverlayOrigin>
      @if (task) {
      <button mat-icon-button type="button" class="ee__button ee__large md-gray-bg mr-4" aria-label="Change due date" matTooltip="Update Deadline"
              (click)="detailsOpen = !detailsOpen"
              [class.open]="detailsOpen">
        <mat-icon fontSet="fa" fontIcon="fa-pen"></mat-icon>
      </button>
      } @else {
        <a cdkOverlayOrigin class="ee__link font-medium text-sm step-due-date-creation-link"
              (click)="detailsOpen = !detailsOpen"
              [class.open]="detailsOpen">
          Set Step Due Date
        </a>
      }
    </div>
    <ng-template
      cdkConnectedOverlay
      cdkConnectedOverlayPush
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayHasBackdrop]="false"
      [cdkConnectedOverlayOpen]="detailsOpen"
      [cdkConnectedOverlayDisableClose]="false"
      [cdkConnectedOverlayDisposeOnNavigation]="true"
      (overlayOutsideClick)="detailsOpen = false">
      <div class="task-details ee__standard-shadow">
        <mat-calendar #calendar [selected]="selectedDate" (selectedChange)="selectionChanged($event)"></mat-calendar>
        <div class="mb-4">
          <div class="task-header">Assigned Users</div>
          <span *ngFor="let user of users; let i = index" class="small mr-1 user cursor-pointer"  [matTooltip]="user.display_name"
                [matTooltipPosition]="'above'" [matTooltipShowDelay]="500" [matTooltipHideDelay]="1000"
                [attr.data-letters]="user.display_name | dataLetters" [ngStyle]="{'--user-selector-color': user.color}"
                [ngClass]="{'selected': selectedUsers[i] }" (click)="toggleUser(i)"></span>
        </div>
        <div class="flex flex-row justify-between">
          <button mat-stroked-button (click)="cancel()">Cancel</button>
          <button mat-flat-button color="accent" (click)="save()">Save</button>
        </div>
      </div>
    </ng-template>
  `,
  styles: `
    @import 'components/color-palette';

    mat-calendar {
      margin-top: -1.5rem;
    }

    .user {
      opacity: .35;

      &:hover, &.selected {
        opacity: 1;
      }
    }

    .task-details {
      background-color: #fff;
      border-radius: 8px;
      padding: 1rem;
      color: #424242;
      font-size: 1rem;
      font-weight: 500;
      width: 300px;

      .task-header {
        font-size: 12px;
        font-weight: 600;
        color: #6F6F6F;
        text-transform: uppercase;
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowTaskInfoComponent implements OnInit, AfterViewChecked {
  @Input({ required: false }) task: BaseTask;
  @Input({ required: false }) users: User[];
  @Output() onSave = new EventEmitter<BaseTask>();
  public selectedDate: DateTime;
  public detailsOpen = false;
  public loading = true;
  public selectedUsers: boolean[] = [];

  // reference the calendar
  @ViewChild(MatCalendar) calendar: MatCalendar<DateTime>;

  ngOnInit() {
    if (this.task) {
      this.selectedDate = DateTime.fromISO(this.task.due_date);
      this.users.forEach(user => {
        this.selectedUsers.push(this.task.assigned_users.findIndex(id => id === user.id) > -1);
      });
    } else {
      this.selectedDate = DateTime.local();
      this.users.forEach(() => {
        this.selectedUsers.push(false);
      });
    }
  }

  ngAfterViewChecked() {
    if (this.loading && this.calendar) {
      this.calendar.activeDate = this.selectedDate;
      this.calendar.updateTodaysDate();
      this.loading = false;
    }
  }

  selectionChanged(event: DateTime) {
    this.selectedDate = event;
    this.calendar.updateTodaysDate();
  }

  toggleUser(index: number) {
    this.selectedUsers[index] = !this.selectedUsers[index];
  }

  cancel() {
    this.detailsOpen = false;
  }

  save() {
    this.detailsOpen = false;
    const assignedUsers = this.users.filter((user, index) => this.selectedUsers[index]).map(user => user.id);
    this.onSave.emit({
      ...this.task ?? { id: null, name: '', type: TaskType.WORKFLOW_STEP },
      due_date: this.selectedDate.toISO(),
      assigned_users: assignedUsers
    });
  }
}
